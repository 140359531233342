.header-carousel .slick-dots li button{
    background-color: #525252;
    opacity: 0.5;
}

.header-carousel .slick-dots li.slick-active button{
    background-color: #000000;
    opacity: 1;
}
.header-carousel .slick-next{
    right: 45px;
    z-index: 10;
}
.header-carousel .slick-prev{
    left: 15px;
    z-index: 10;
}
.header-carousel>.slick-prev path, .header-carousel>.slick-next path{
    fill: #222222;
}
.mobile-carousel .slick-prev{
    top:350px;
}
.mobile-carousel .slick-next{
    top:350px;
}

@media (max-width:766px) {
    .header-carousel .slick-dots-bottom{
        bottom: 0;
    }
}