.partner-carousel .slick-dots li button{
    background-color: #eee;
    opacity: 0.5;
}
.partner-carousel .slick-dots li.slick-active button{
    background-color: #fff;
    opacity: 1;
}
.partner-carousel>.slick-prev path, .slick-next path{
    fill: #fff;
}

.partner-carousel .slick-next{
    right: 145px;
    top:55%;
    z-index: 10;
}
.partner-carousel .slick-prev{
    left: 145px;
    top:55%;
    z-index: 10 ;
}
@media (max-width:766px) {
    .partner-carousel .slick-prev{
        display: none !important;
    }
}
@media (max-width:766px) {
    .partner-carousel .slick-next{
        display: none !important;
    }
}